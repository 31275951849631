import { format } from "date-fns"
import React from "react"
import { SidebarLayout } from "../components/layout"
import { Sidebar } from "../components/sidebar"

export const Competition: React.FC<any> = ({ pageContext }) => {
  const { competition, competitionSlug, sidebar } = pageContext
  const startDate = format(new Date(competition.start_date), "iii do LLL yyyy")
  const endDate = format(new Date(competition.end_date), "iii do LLL yyyy")

  return (
    <SidebarLayout
      sidebar={{
        competitionSlug,
        navigation: sidebar.navigation,
      }}
      competition={competition}
    >
      <div className="px-5">
        <div className="mb-8">
          <h2 className="font-primary font-black text-3xl">
            {competition.title.text}
          </h2>
          <time>
            {startDate} - {endDate}
          </time>
        </div>

        {competition.jot_form_link.url ? (
          <iframe
            width="100%"
            height="900"
            src={competition.jot_form_link.url}
          />
        ) : null}
      </div>
    </SidebarLayout>
  )
}

export default Competition
